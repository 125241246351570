import React, { useEffect } from 'react'
import '../styles/Body.css'

import { Abilities } from './body-components/Abilities'
import { Projects } from './body-components/Projects'
import { TechnicalProficency } from './body-components/TechnicalProficency'
import { AboutProjects } from './body-components/AboutProjects'
import { BrowserRouter, HashRouter, Switch, Routes, Route } from "react-router-dom"



export const Body = ({ handleSectionVisibility, seeSectionVisibility, translations, isTranslateLoaded, currentLang }) => {

    useEffect(() => {
        //seeSectionVisibility
    }, [])

    {/* If user has clicked a link for a section, take him there, copy where needed */ }
    useEffect(() => {
        const hash = window.location.hash
        if (hash) {
            const element = document.querySelector(hash)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        } else {
            window.scrollTo(0, 0)
        }
    }, [])


    return (
        <div id="general-container">

            {/* <HashRouter> */}


            <section id="about-projects">
                <AboutProjects translations={translations} isTranslateLoaded={isTranslateLoaded} />
            </section>


            <section id="technical-proficiency">
                <TechnicalProficency translations={translations} isTranslateLoaded={isTranslateLoaded} />
            </section>

            <section id="abilities">
                {/* <Routes>
                    <Route exact path="/" component={Abilities} />
                </Routes> */}
                <Abilities handleSectionVisibility={handleSectionVisibility} translations={translations} isTranslateLoaded={isTranslateLoaded} />
            </section>

            <section id="projects">
                {/* <Routes>
                    <Route path="/" component={Projects} />
                </Routes> */}
                <Projects translations={translations} isTranslateLoaded={isTranslateLoaded} currentLang={currentLang} />
            </section>



            {/* <section id="about-this-website" >
                <Routes>
                    <Route path="/" component={AboutWebsite} />
                </Routes>
                <AboutWebsite />
            </section> */}


            {/* </HashRouter> */}

        </div>
    )
}
