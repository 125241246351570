// CookieNotice.js
import React, { useState, useEffect } from 'react'
import '../../styles/cookieNotice.css'



export const CookieNotice = ({ handleCookieNoticeChange, showCookieBanner, currentLang }) => {

    //    const [showNotice, setShowNotice] = useState(true)

    useEffect(() => {
        // Check if the user has previously accepted the cookie notice
        const hasAccepted = localStorage.getItem('cookieNoticeAccepted')
        if (hasAccepted) {
            handleCookieNoticeChange(false)
        }
    }, [])

    const handleAccept = () => {
        // Set a flag in localStorage to indicate the user has accepted the cookie notice
        localStorage.setItem('cookieNoticeAccepted', 'true')
        localStorage.setItem('userLanguage', currentLang)
        handleCookieNoticeChange(false)
    }

    const handleDecline = () => {
        // Set a flag in localStorage to indicate the user has accepted the cookie notice
        //localStorage.setItem('cookieNoticeAccepted', 'false')
        handleCookieNoticeChange(false)
    }



    return (
        showCookieBanner && (
            <div className="cookie-notice">
                <p>Este sitio web utiliza cookies para funcionalidades esenciales, incluyendo preferencias de idioma.</p>
                <button onClick={handleAccept}>Aceptar</button>
                <button onClick={handleDecline}>Rechazar</button>
            </div>
        )
    )
}

