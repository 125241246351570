import React from 'react'
import '../styles/Footer.css'
import { FaGithub, FaEnvelope, FaLinkedin } from "react-icons/fa"
import { Link } from 'react-router-dom'



export const Footer = ({ isTranslateLoaded = false, translations, currentLang, activeNavbar }) => {


    if (!isTranslateLoaded) {
        return
    } else {


        return (
            <div id="footer-container">
                <div>
                    <div id="footer-title-container">
                        <p id="footer-title">{translations[1]?.footer.title}</p>
                    </div>
                </div>

                <div>
                    <div id="footer-square-container">
                        <p id="footer-square-text">
                            {translations[1]?.footer.p1}
                        </p>
                        <div id="footer-square-mail-container">
                            <a href={`mailto:martinzazpe1@gmail.com?subject=${translations[1]?.footer.emailSubject}`} target="_blank">
                                <FaEnvelope className="social-icon-footer fa-lg" />
                            </a>
                        </div>
                    </div>
                </div>

                <div id="footer-links-section">
                    <div id="footer-links">
                        <Link to="/">{translations[1]?.footer.navHome}</Link>
                        <Link to="/about-this-website">{translations[1]?.footer.navAboutWebsite}</Link>
                        <Link to="/about-me">{translations[1]?.footer.navAboutMe}</Link>
                        <a href="https://drive.google.com/file/d/1whb4PkP7FGC_04ksndHTTME_FBzCa4wX/view?usp=drive_link" target="_blank">{translations[1]?.footer.navResume}</a>
                    </div>
                </div >

                <div id="footer-external-links-section">
                    <div id="footer-external-links">
                        <a href="https://www.linkedin.com/in/martin-zazpe-290829121/" target="_blank">  <   FaLinkedin className=" fa-xl footer-icon" /></a>
                        <a href="https://github.com/MartinZazpe" target="_blank"><   FaGithub className=" fa-xl footer-icon" /></a>
                    </div>
                </div>

                <div>
                    <div id="copyright-container">
                        <p id="copyright-text">{translations[1]?.footer.copyrightp1}<span>{translations[1]?.footer.copyrightp2}</span></p>
                    </div>
                </div>

            </div >
        )
    }
}
