import React from 'react'
import '../../styles/languageSelector.css'


export const LanguageSelector = ({ currentLang, onLangChange, handleCookieNoticeChange }) => {


  const handleLangClick = (lang) => {
    if (localStorage.getItem('cookieNoticeAccepted')) {
      onLangChange(lang)
      localStorage.setItem('userLanguage', lang)
    } else {
      handleCookieNoticeChange(true)
    }
  }

  return (
    <>
      <div className="language-switcher">
        <button onClick={() => handleLangClick('en')} className={currentLang === 'en' ? 'active' : ''}>
          🇺🇸 Eng
        </button>
        <p>|</p>
        <button onClick={() => handleLangClick('es')} className={currentLang === 'es' ? 'active' : ''}>
          🇪🇸 Esp
        </button>
        {/* Add more language buttons as needed */}
      </div>
    </>
  )
}
