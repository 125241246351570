import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import '../../styles/projects.css'
import { FaGithub } from "react-icons/fa"


import ClipLoader from 'react-spinners/ClipLoader'


// Getting all the images
import errorImage from '../../Images/repoImages/no-image-available.png'


const screenshotPath = 'src/Images'


export const Projects = ({ isTranslateLoaded = false, translations, currentLang }) => {

    //projectList from github API.
    const [projectList, setProjectList] = useState([])

    //this will define when projectList has obtained elements;
    const [projectsFetched, setProjectsFetched] = useState(false)


    const [loadingFromPupp, setloadingFromPupp] = useState(true)

    const [imageUrls, setImageUrls] = useState([])

    //default image if no image is available
    const [imgSource, setImageSource] = useState()

    //current selected miniature;
    const [selectedMiniature, setSelectedMiniature] = useState()

    const [loading, setLoading] = useState(true)

    const [loadingFailed, setloadingFailed] = useState(false)

    {/*Project filtering*/ }
    const [selectedFilter, setSelectedFilter] = useState('All')
    const selectedFilterRef = useRef(selectedFilter)
    let projectsFound


    {/* server endpoints*/ }
    const getScreenShotEndpoint = 'https://martinzazpe.com/portfolio-server/get-url-screenshot?url='
    const getGithubReposEndpoint = 'https://martinzazpe.com/portfolio-server/githubRepos'
    const getScreenShotEndpointDEV = 'http://localhost:3011/portfolio-server/get-url-screenshot?url='
    const getGithubReposEndpointDEV = 'http://localhost:3011/portfolio-server/githubRepos/'



    {/* If user has clicked a link for a section, take him there, copy where needed */ }
    useEffect(() => {
        const hash = window.location.hash
        if (hash) {
            const element = document.querySelector(hash)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            } else {
                window.scrollTo(0, 0)
            }
        }
    }, [])


    {/* Project filters*/ }
    const handleFilterSelect = (filter) => {
        setSelectedFilter(filter)
        selectedFilterRef.current = filter // Update the ref

        // setTimeout(() => {
        //     console.log(selectedFilterRef.current.toLowerCase()) // Access the value through the ref
        // }, 500)
    }


    {/*Method to get an image for a project */ }
    const getProjectImage = async (url) => {
        try {
            if (url != null) {
                const response = await fetch(`${process.env.NODE_ENV == 'development' ? getScreenShotEndpointDEV : getScreenShotEndpoint}${url}`, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_SERVER_TOKEN}`,
                    }
                })
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                const imageBlob = await response.blob()
                const imageUrl = URL.createObjectURL(imageBlob)

                return imageUrl
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error)
            // Return a default image URL or handle the error as needed
            return 'default-image-url'
        }
    }



    {/* Fetch project repo & puppeteer images*/ }
    useEffect(() => {
        const fetchDataAndPreloadImages = async () => {
            try {
                setLoading(true)


                if (!imageUrls || imageUrls.length === 0) {
                    const response = await axios.get(
                        process.env.NODE_ENV == 'development' ? getGithubReposEndpointDEV :
                            getGithubReposEndpoint, {
                        headers: {
                            'Authorization': `Bearer ${process.env.REACT_APP_SERVER_TOKEN}`,
                        }
                    })
                    const newArray = response.data
                    setProjectList(newArray)
                    setProjectsFetched(true)

                    const urls = await Promise.all(
                        newArray.map(async (project) => {
                            return await getProjectImage(project.homepage)
                        })
                    )

                    await Promise.all(
                        urls.map(async (imageUrl) => {
                            const response = await fetch(imageUrl)
                            const blob = await response.blob()
                        })
                    )
                    setLoading(false)
                    setImageUrls(urls)
                    setloadingFromPupp(false)
                } else {
                    // setImageUrls()
                    setloadingFromPupp(false)
                    setLoading(false)
                }
            } catch (error) {
                // console.error('Error fetching data and preloading images:', error)
            }
        }

        fetchDataAndPreloadImages()
    }, [])
    // ... (rest of the component code)


    {/* Timeout for project loader */ }
    useEffect(() => {
        if (loading) {
            const loadingTimeout = setTimeout(() => {
                if (projectList.length === 0) {
                    setloadingFailed(true)
                    setLoading(false)
                }
            }, 5000)
            // Clean up the timeout if the component unmounts or loading completes
            return () => clearTimeout(loadingTimeout)
        }
    }, [loading])


    { /*  Use another useEffect to handle the case when images load successfully.   */ }
    useEffect(() => {
        // Check if images are loaded
        if (projectList.length > 0) {

            const resetTimeout = setTimeout(() => {
                setloadingFailed(false)
            }, 6000)

            // Clean up the reset timeout if the component unmounts
            return () => clearTimeout(resetTimeout)
        }
    }, [projectList])



    // select miniature project
    const miniatureSelect = async (miniature) => {
        if (miniature != null) {
            // console.log('this is miniature to be attached ' + miniature.name)
            // Use the preloaded image URL
            setSelectedMiniature({
                ...miniature,
                imageUrl: imageUrls[projectList.indexOf(miniature)] || 'default-image-url',
            })
        } else {
            if (projectList != null && (!selectedMiniature || Object.keys(selectedMiniature).length === 0)) {
                let defaultMini = projectList.find((ele) => ele.name === 'The-Good-Kitchen')
                // console.log('Default Miniature:', defaultMini) // Add this line

                try {
                    const imageUrl = await getProjectImage(defaultMini.homepage)
                    setSelectedMiniature({
                        ...defaultMini,
                        imageUrl: imageUrl,
                    })
                } catch (error) {
                    // console.error('Error fetching default image:', error)
                }
            }
        }
    }


    // Set default miniature selected
    useEffect(() => {
        if (projectsFetched == true) {
            miniatureSelect()
        }
    }, [projectsFetched])


    //set image of inital miniature by default
    useEffect(() => {
        let isMounted = true
        const fetchImage = async () => {
            if (selectedMiniature) {
                try {
                    // Fetch and set the image URL for the default miniature
                    const imageUrl = await getProjectImage(selectedMiniature.homepage)
                    if (isMounted) {
                        setSelectedMiniature(prevMiniature => ({
                            ...prevMiniature,
                            imageUrl: imageUrl
                        }))
                    }
                } catch (error) {
                    // console.error('Error fetching default image:', error)
                }
            }
        }

        fetchImage()
        // Cleanup function to set isMounted to false when the component unmounts
        return () => {
            isMounted = false
        }
    }, [])



    //setting the default Image for project
    useEffect(() => {
        setImageSource(errorImage)
    }, [])
    //on Image error
    const onImageError = (e) => {
        e.target.src = imgSource
    }




    if (!isTranslateLoaded) {
        return
    } else {




        return (
            <div id="projects-general-container">

                {loading && (
                    <div className="loading-spinner fetching-projects">

                        {currentLang == 'en' && <p>Fetching projects...</p>}
                        {currentLang == 'es' && <p>Cargando proyectos desde github...</p>}
                        <ClipLoader className='fetching-projects' size={100} color={'#123abc'} loading={loading} />
                    </div>
                )}



                <div className="projects-container-mobile">
                    <div className="project-title-container-mobile">
                        <h3 className="projects-title-mobile">{translations[1]?.projects.mobileTitlep1}<span>{translations[1]?.projects.mobileTitlep2}</span></h3>
                        <em>{translations[1]?.projects.mobileSubtitle}</em>
                    </div>
                    {
                        projectList != null &&
                        projectList.map((element, index) => {
                            // id = element.id
                            return <div key={element.id} className={index % 2 == 0 ? "project-reverse project" : "project"} >
                                <div className="project-image-container">
                                    <a href={element.homepage} target="_blank" >
                                        <img className="project-image"
                                            src={imageUrls[index] || 'default-image-url'}
                                            onError={onImageError}
                                            alt={element.name} />
                                    </a>

                                    <div className="project-text-container">
                                        <div>
                                            <p className="project-title"> {element.name}</p>
                                        </div>
                                        <div>
                                            <p className="project-description">{
                                                element.description &&
                                                element.description.substring(0, 80) + "..."
                                            }</p>
                                        </div>

                                        <div className="project-code-container">

                                            <a href={element.html_url} target="_blank" className={"project-code-link-right image-code-absolute"}   >
                                                View code
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        })
                    }
                </div>
                {loadingFailed && (
                    <div className='loadingFailed'>
                        {currentLang == 'en' && <p>Sorry, fetching github repos failed.</p>}
                        {currentLang == 'es' && <p>Lo siento, hubo un problema trayendo los repos de github.</p>}
                    </div>
                )}

                <div className="projects-container">

                    <div className="project-title-container">
                        <h3 className="projects-title">{translations[1]?.projects.title}</h3>
                    </div>

                    <div class="image-information">
                        <div className="image-miniature-container" >
                            {
                                selectedMiniature != null &&
                                <img className='selectedMiniature-container'
                                    src={selectedMiniature.imageUrl || 'default-image-url'}
                                ></img>
                            }
                        </div>
                        <div className="text-links-container">
                            {
                                selectedMiniature != null &&
                                <div className="project-information-container"
                                >
                                    <p className="selectedMiniature-title">{selectedMiniature.name} </p>
                                    <p className="project-description">{selectedMiniature.description.substring(0, 50) + "..."}</p>
                                    <div className="project-topics">
                                        <div>
                                            {
                                                selectedMiniature.topics != [""] &&
                                                <p className="topics-title">{translations[1]?.projects.topics}:</p>
                                            }
                                        </div>
                                        <div>
                                            {
                                                selectedMiniature.topics != [""] &&
                                                selectedMiniature.topics.map(topic => {
                                                    return <p className="topic"> {topic} </p>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="project-code-container">
                                        <a className="project-code-link" href={selectedMiniature.homepage} target="_blank">{translations[1]?.projects.viewSite}</a>
                                        <a className="project-code-link" href={selectedMiniature.html_url} target="_blank">{translations[1]?.projects.viewCode}
                                            <   FaGithub className="code-icon" />
                                        </a>
                                    </div>
                                </div>
                            }
                            {/* <div className="load-more-projects">
                                <a href="https://github.com/MartinZazpe?tab=repositories" target="_blank">
                                    <p>View all projects</p>
                                    <div className="dark-color"> &lt;/&gt;  </div>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div >


                <div className='miniatures-whole-container'>


                    <div className="miniatures-filter">
                        <div>
                            <p>{translations[1]?.projects.filterTitle}</p>
                        </div>
                        <div className='miniatures-filter-nav'>
                            <p className={selectedFilter == ('All' || 'Todos') ? 'selected-filter-style' : ''} onClick={() => handleFilterSelect('All')}>{translations[1]?.projects.allFilter}</p>
                            <p className={selectedFilter == 'Java' ? 'selected-filter-style' : ''} onClick={() => handleFilterSelect('Java')}>Java</p>
                            <p className={selectedFilter == 'NodeJs' ? 'selected-filter-style' : ''} onClick={() => handleFilterSelect('NodeJs')}>NodeJs</p>
                            <p className={selectedFilter === 'React' ? 'selected-filter-style' : ''} onClick={() => handleFilterSelect('React')}>React</p>
                        </div>
                    </div>

                    <div className="miniatures-container">
                        {/* <div className="project-title-container">
                        <h3 className="projects-title special-title">Check a few more projects</h3>
                    </div> */}
                        {
                            projectList != null && (
                                projectList.map((miniature, index) => {
                                    if (miniature.homepage && (selectedFilter === 'All' || miniature.topics.some(top => top === selectedFilter.toLowerCase()))) {
                                        projectsFound = true


                                        return <div
                                            onClick={() => miniatureSelect(miniature)}
                                        >
                                            <img
                                                src={imageUrls[index] || 'default-image-url'}
                                                onError={onImageError}
                                                className={`miniatureImg ${selectedMiniature && miniature.name === selectedMiniature.name ? "" : "miniature-name"}`}
                                                alt={miniature.name}
                                            />

                                            <p
                                                className={
                                                    selectedMiniature != null &&
                                                        miniature.name === selectedMiniature.name ? "selectedMiniFont" : "miniature-name"
                                                }
                                            >
                                                {miniature.name}
                                            </p>
                                        </div>
                                    }


                                })
                            )}
                    </div>
                    {(!projectsFound && currentLang == "en") && <p className="no-projects-found">Sorry no projects were found for this technology, they could be getting updated.</p>}
                    {(!projectsFound && currentLang == "es") && <p className="no-projects-found">Lo siento, no se han encontrado proyectos para estás tecnologías, puede que estén actualizandose!</p>}
                </div >
            </div>
        )
    }
}

