import React from 'react'
import AboutWebsite from '../about-website-components/AboutWebsite'

function AboutWebsiteLayout({ isTranslateLoaded = false, translations, currentLang }) {
    return (
        <div id="about-website-container" >
            <AboutWebsite translations={translations} isTranslateLoaded={isTranslateLoaded} currentLang={currentLang} />
        </div>
    )
}

export default AboutWebsiteLayout
