import React from 'react'
import { FaGithub, FaEnvelope, FaLinkedin } from "react-icons/fa"



export const SocialLinksString = () => {
    return (
        <div id="socialLinks-Container">
            <hr size="400" style={{ "margin": "0 auto" }} />
            <div class="socials-container">
                <a href="https://github.com/MartinZazpe" target="_blank">
                    <   FaGithub className="social-icon fa-lg" />
                </a>
                <a href="mailto:martinzazpe1@gmail.com?subject=Hello, let's get in touch" target="_blank">
                    <   FaEnvelope className="social-icon fa-lg" />
                </a>
                <a href="https://www.linkedin.com/in/martin-zazpe-290829121/" target="_blank">
                    <   FaLinkedin className="social-icon fa-lg" />
                </a>
            </div>
        </div>
    )
}
