import React from 'react'
import { useLocation } from 'react-router'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'
import { LanguageSelector } from './LanguageSelector'



export const Navbar = ({ handleSectionVisibility, isTranslateLoaded = false, translations, currentLang, onLangChange, handleCookieNoticeChange, hambMenuInteraction }) => {

    const location = useLocation()
    const isNotFound = location.pathname === '/not-found'
    const isAboutWebsite = location.pathname === '/about-this-website'


    function handleMultipleActions(Section) {

        hambMenuInteraction()

        handleSectionVisibility('')

    }


    if (!isTranslateLoaded) {
        return
    }
    else {
        return (
            <div>
                <ul id='navbar'>
                    <li>
                        <Link smooth to="/about-me" onClick={() => hambMenuInteraction()}>
                            {translations[1]?.navbar.aboutMe}
                        </Link>
                    </li>
                    <li>
                        <HashLink smooth to="/#projects" onClick={() => handleMultipleActions('projects')}  >
                            {translations[1]?.navbar.projects}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink smooth to="/#footer-container" onClick={() => handleMultipleActions('footer-container')}>
                            {translations[1]?.navbar.contact}
                        </HashLink>
                    </li>
                    <LanguageSelector className="language-container" currentLang={currentLang} onLangChange={onLangChange} handleCookieNoticeChange={handleCookieNoticeChange} />

                </ul>
            </div>
        )
    }
}
