import React from 'react'
import '../../styles/Header.css'



export const OverLay = ({ hambMenuInteraction }) => {

    const hideNavbar = () => {
        // Add the logic to hide the navbar here

        console.log('now touching the overlay')

        //for navbar display..
        hambMenuInteraction()
    }

    return (
        <div id="overlay" className='showOverlay' onClick={() => hideNavbar()}></div>
    )
}
