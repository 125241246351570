import React, { useEffect, useRef, useState } from 'react'
import '../styles/Header.css'
import { Navbar } from './header-components/Navbar'
import { Banner } from './header-components/Banner'
import { LanguageSelector } from './header-components/LanguageSelector'

import ClipLoader from 'react-spinners/ClipLoader'
import nextPageIcon from './../Images/downPageIcon.png'


import { HashRouter } from "react-router-dom"
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopCode, faGamepad } from "@fortawesome/free-solid-svg-icons"
import { faCircleDown } from "@fortawesome/free-regular-svg-icons"

import { OverLay } from './header-components/OverLay'

export const Header = ({ handleSectionVisibility, currentLang, onLangChange, isTranslateLoaded, translations, handleCookieNoticeChange, hambMenuInteraction, activeNavbar, goToNextSection }) => {


    const location = useLocation()
    const isNotFound = location.pathname === '/not-found'
    const isAboutWebsite = location.pathname === '/about-this-website'
    const isLanding = location.pathname === '/'




    return (
        <div id="header">
            <div id="logo-menu">
                <div className='logo-language-container'>
                    <div id="logo">
                        <a href="/">mz#</a>
                    </div>
                </div>
                <div id="hamburguer-menu" onClick={() => hambMenuInteraction()} >
                    <div className={`${activeNavbar ? 'openMenuTop' : ''}`}></div>
                    <div className={`${activeNavbar ? 'openMenuMiddle' : ''}`}></div>
                    <div className={`${activeNavbar ? 'openMenuBottom' : ''} `}></div>
                </div>

                <div id="navbar-container-id" className={activeNavbar ? 'visibleNavbar' : 'navbar-container'}>
                    <Navbar handleSectionVisibility={handleSectionVisibility} isTranslateLoaded={isTranslateLoaded} translations={translations} currentLang={currentLang} onLangChange={onLangChange} handleCookieNoticeChange={handleCookieNoticeChange} hambMenuInteraction={hambMenuInteraction} />
                    <OverLay hambMenuInteraction={hambMenuInteraction} />
                </div>

            </div>

            {!isTranslateLoaded ? (
                <div className="loading-spinner fetching-language">
                    {currentLang == 'en' && <p>Fetching translation & loading website...</p>}
                    {currentLang == 'es' && <p> Obteniendo traducción & cargando sitio...</p>}
                    <ClipLoader size={30} color={'#123abc'} />
                </div>
            ) : null
            }


            {isLanding ? (
                <div id="all-banner-container" className={activeNavbar ? 'adjust-content-for-navbar ' : ''}>
                    <div id="title-container">
                        <Banner isTranslateLoaded={isTranslateLoaded} translations={translations} />
                    </div>
                </div>
            ) : null}


            <div className='next-section-icon-white' onClick={() => goToNextSection('about-projects')}>
                {/* <img src={nextPageIcon} alt="" /> */}
                <FontAwesomeIcon icon={faCircleDown} className="fa-2xl" style={{ color: "#ffffff", }} />
            </div>


        </div>

    )

}
