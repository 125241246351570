import React from 'react'
import '../../styles/aboutProjects.css'
// import ballena3d from '../../Images/ballena-imagen3d.webp'



export const AboutProjects = ({ isTranslateLoaded = false, translations }) => {
    return (
        <div id="about-projects-container" >
            {/* <div className='about-projects-bg'>    </div> */}

            <h4> {translations[1]?.aboutProjects.title}</h4>
            <div id='about-projects-paragraph-container'>
                <p>
                    {translations[1]?.aboutProjects.p1}
                </p>
                <br />
                <p>
                    {translations[1]?.aboutProjects.p2}
                </p>
                <br />
                <p>
                    {translations[1]?.aboutProjects.p3}
                </p>

            </div>
        </div>
    )
}
