import React from 'react'
import '../../styles/technicalProficiency.css'


export const TechnicalProficency = ({ isTranslateLoaded = false, translations }) => {


    if (!isTranslateLoaded) {
        return
    } else {
        return (
            <div id="technical-proficiency-container">
                <div class="technical-skill background1">
                    <h6>{translations[1]?.technicalProf.first}</h6>
                    <em>{translations[1]?.technicalProf.firstDesc}</em>
                </div>

                <div class="technical-skill background2">
                    <h6>{translations[1]?.technicalProf.second}</h6>
                    <em>{translations[1]?.technicalProf.secondDesc}</em>
                </div>

                <div class="technical-skill background1">
                    <h6>{translations[1]?.technicalProf.third}</h6>
                    <em>{translations[1]?.technicalProf.thirdDesc}</em>
                </div>

            </div>
        )
    }
}