import React, { useEffect, useState } from 'react'
import '../../styles/abilities.css'
import { faLaptopCode, faGamepad } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cssLogo from '../../Images/CssLogo.png'
import htmlLogo from '../../Images/htmlLogo.png'
import jsLogo from '../../Images/JsLogo.png'
import unityLogo from '../../Images/UnityLogo.png'
import garagebandLogo from '../../Images/garagebandLogo.png'
import asepriteLogo from '../../Images/asepriteLogo.png'


export const Abilities = ({ translations, isTranslateLoaded = false }) => {

    // This will handle which ability box 1 is active;
    const [box1Active, setBox1Active] = useState(false)

    // This will handle which ability box 1 is active;
    const [box2Active, setBox2Active] = useState(false)

    // const [translationsx, settranslationsx] = useState(translations)

    // useEffect(() => {
    //     settranslationsx(translations)
    // }, [translations])


    // const handleActiveBox = event => {
    //     let currentT = event.currentTarget.id
    //     if (currentT === "ability1") {
    //         setBox1Active(!box1Active)
    //     }

    //     else if (currentT === "ability2") {
    //         setBox2Active(!box2Active)
    //     }
    // }

    const displayBox2 = event => {
        setBox2Active(!box1Active)
    }


    if (!isTranslateLoaded) {
        return
    }
    else {
        return (
            <div id="abilities-container-container">
                {/* <div className='about-projects-bg'>    </div> */}

                <div id="abilities-title">
                    <h1>{translations[0]?.abilities.abilitiesTitle}</h1>
                    {/* <h1>What can I do?</h1> */}
                </div>

                <div id="abilities-container">
                    {/* className={box1Active ? 'abilityExpanded' : (box2Active ? "noDisplay" : "ability")} onClick={handleActiveBox} */}
                    <div id="ability1" className='abilityExpanded' >
                        <div className="icon-title-container">
                            <FontAwesomeIcon id="computer-code-icon" className="fa-lg" icon={faLaptopCode} />
                            <div className="ability-title">
                                <p>{translations[0]?.abilities.ability1title1}</p>
                                <p>{translations[0]?.abilities.ability1title2}</p>
                            </div>
                        </div>

                        {/* {box1Active ? */}
                        <div className="ability-content">
                            <div className="ability-text-container">
                                <p>
                                    {translations[0]?.abilities.ability1p1}
                                </p>
                                <p>
                                    {translations[0]?.abilities.ability1p2}
                                </p>
                                <p>
                                    {translations[0]?.abilities.ability1p3}
                                </p>
                            </div>
                        </div>
                        {/* :''} */}
                        {/* <div className="expand-shrink-link-container">
                            {!box1Active ?
                                <p className="expand-shink-link" href=""> {translations[0]?.abilities.abilityExpand}</p>
                                :
                                <p className="expand-shink-link" href="">{translations[0]?.abilities.abilityShrink}</p>
                            }
                        </div> */}
                    </div>

                    <div className="ability-list">
                        <div>
                            <p><strong>{translations[0]?.abilities.abilityTools}:</strong></p>
                            <ul>
                                <li>Htm, Css, Javascript</li>
                                <li>NodeJs</li>
                                <li>ReactJs</li>
                                <li>JAVA</li>
                                <li>Mysql</li>
                                <li>Git</li>
                                <li>{translations[0].abilities.agileMethodologies}</li>
                            </ul>
                        </div>
                        <div className="ability-icons">
                            <img className="htmlLogo" src={htmlLogo} alt="Html logo" />
                            <img className="cssLogo" src={cssLogo} alt="Css logo" />
                            <img className="jsLogo" src={jsLogo} alt="Js logo" />
                        </div>
                    </div>

                    <p className='videogameText'>{translations[0].abilities.videoGamesText}<span className='displayGameCreationLink' onClick={displayBox2}>{translations[0].abilities.videoGamesTextLink}</span></p>

                    <div id="ability2" className={box2Active ? 'abilityExpanded' : 'noDisplay'}>
                        <div className="icon-title-container">
                            <FontAwesomeIcon id="computer-code-icon" className="fa-lg" icon={faGamepad} />
                            <div className="ability-title">

                                <p>{translations[0]?.abilities.ability2title1}</p>

                                <p>{translations[0]?.abilities.ability2title2}</p>

                            </div>
                        </div>



                        {box2Active ?
                            <div className="ability-content">
                                <div className="ability-text-container">
                                    <p>
                                        {translations[0]?.abilities.ability2p1}
                                    </p>
                                    <p>
                                        {translations[0]?.abilities.ability2p2}
                                    </p>
                                    <p>
                                        {translations[0]?.abilities.ability2p3}
                                    </p>
                                    <p>
                                        {translations[0]?.abilities.ability2p4}
                                    </p>
                                </div>
                                <div className="ability-list">
                                    <div className="games-tools-container">
                                        <p><strong>{translations[0]?.abilities.abilityTools}:</strong></p>
                                        <ul className="game-icon-list">
                                            {/* <li>Unity Engine</li>
                                        <li>Aseprite</li>
                                        <li>Music Daw (garageband)</li> */}
                                            <li><img src={unityLogo}></img></li>
                                            <li><img src={asepriteLogo}></img></li>
                                            <li className="garagebandLi"><img src={garagebandLogo}></img>
                                                <span>Garageband</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="games-List">
                                    <div>
                                        <p>{translations[0]?.abilities.playGame} &nbsp;
                                            <a href="https://martinzg.itch.io/demon-me" target="_blank"> Demon-me game</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            : ''}

                        {/* <div className="expand-shrink-link-container">
                            {!box2Active ?
                                <p className="expand-shink-link" href=""> {translations[0]?.abilities.abilityExpand}</p>
                                :
                                <p className="expand-shink-link" href="">{translations[0]?.abilities.abilityShrink}</p>
                            }
                        </div> */}
                    </div>


                </div>

            </div >


        )
    }
}   
