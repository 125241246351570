import React, { useEffect } from 'react'
import '../../styles/aboutWebsite.css'
import { Link } from 'react-router-dom'


export const AboutWebsiteLayoutNavbar = ({ isTranslateLoaded = false, translations, currentLang }) => {

    useEffect(() => {
        const hash = window.location.hash
        if (hash) {
            const element = document.querySelector(hash)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        } else {
            window.scrollTo(0, 0)
        }
    }, [])


    if (!isTranslateLoaded) {
        return
    } else {
        return (
            <div id="about-website-layout-navbar-container">
                <div>
                    <h6>{translations[1]?.aboutWebNav.title} </h6>
                    <p> {translations[1]?.aboutWebNav.p1}</p>
                    <p>{translations[1]?.aboutWebNav.p2}</p>
                </div>

                <nav id="about-website-links">
                    <Link to="/">{translations[1]?.aboutWebNav.linkHome}</Link>
                    <Link to="/#projects"><p>{translations[1]?.aboutWebNav.linkProjects}</p></Link>
                    <a href="https://shorturl.at/qu569" target="_blank">{translations[1]?.aboutWebNav.linkResume}</a>
                </nav>
            </div>
        )
    }
}
