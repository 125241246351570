import logo from './logo.svg'
import './styles/App.css'
// import { Main } from './Components/main'  --Main has the mongoDB logic.
import { Header } from './Components/Header'
import { Body } from './Components/Body'
import { Footer } from './Components/Footer'
import { CookieNotice } from './Components/handler-components/CookieNotice'
import { SocialLinksString } from './Components/SocialLinksString'
import "@fortawesome/fontawesome-svg-core/styles.css"


import AboutWebsiteLayout from './Components/body-components/AboutWebsiteLayout'

import { BrowserRouter as Router, Route, Routes, useLocation, HashRouter } from 'react-router-dom'

import { useEffect, useRef, useState } from 'react'
import { NotFound } from './Components/body-components/NotFound'
import CatchAllRoute from './Components/handler-components/CatchAllRoute'
import { AboutWebsiteLayoutNavbar } from './Components/about-website-components/AboutWebsiteLayoutNavbar'
import { AboutMe } from './Components/body-components/AboutMe'




function App() {

  document.title = `Martin's website`

  const [visibleSections, setVisibleSections] = useState({
    abilities: false,
    projects: false,
    aboutMe: false
  })

  const [visibleSectionsProp, setvisibleSectionsProp] = useState()

  const [translations, setTranslations] = useState({})
  const [currentLang, setCurrentLang] = useState(localStorage.getItem('userLanguage') ?? 'es')
  const [isTranslateLoaded, setTranslateLoaded] = useState(false)
  const [showCookieBanner, setShowCookieBanner] = useState(true)


  //endpoints
  const languageEndpoint = 'https://www.martinzazpe.com/portfolio-server/languageTranslate/'
  const languageEndpointDev = 'http://localhost:3011/portfolio-server/languageTranslate/'


  //this will handle cookieNotice
  const handleCookieNoticeChange = (newValue) => {
    setShowCookieBanner(newValue)
  }

  //language selection
  useEffect(() => {
    const fetchTranslations = async () => {
      setTranslateLoaded(false)
      try {
        const response = await fetch(`${process.env.NODE_ENV == 'development' ?
          languageEndpointDev : languageEndpoint}${currentLang}`,
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_SERVER_TOKEN}`,
            }
          })

        if (response.ok) {
          let data = await response.json()

          setTranslations(data)
          setTranslateLoaded(true)
        } else {
          console.error('Error fetching translations. Response not OK')
        }
      } catch (error) {
        console.error('Error fetching translations:', error)
      }
    }
    if (!isTranslateLoaded) {
      fetchTranslations()
    }
  }, [currentLang, isTranslateLoaded])
  //isTranslateLoaded could be added to useEFFECT to listen, removed for testing...

  const handleLangChange = (lang) => {
    //if cookies are accepted then set the current language and store it in local storage
    if (localStorage.getItem('cookieNoticeAccepted')) {
      setCurrentLang(lang)
      setTranslateLoaded(false) // Reset the loaded state to fetch translations again
      setShowCookieBanner(false)
    } else {
      setShowCookieBanner(true)
    }
  }


  //pathing & section visibility
  useEffect(() => {
    setvisibleSectionsProp(visibleSections)
  }, [visibleSections])

  const handleSectionVisibility = (name) => {

    switch (name) {
      case "abilities":
        return setVisibleSections({
          abilities: true,
          projects: true,
          aboutMe: true,
        })
        break
      case "projects":
        return setVisibleSections({
          abilities: true,
          projects: true,
          aboutMe: true,
        })
        break
      case "about-me":
        return setVisibleSections({
          abilities: true,
          projects: true,
          aboutMe: true,
        })
        break
      default:
        return null
    }
  }
  const location = useLocation()
  const isNotFound = location.pathname === '/not-found'
  const isAboutWebsite = location.pathname === '/about-this-website'
  const isAboutMe = location.pathname === '/about-me'

  //Handle the state of active or inactive hambmenu navbar
  const [activeNavbar, setactiveNavbar] = useState(false)

  function hambMenuInteraction() {
    // console.log('Toggling navbar')
    // console.log(activeNavbar)

    setactiveNavbar(!activeNavbar)
    if (activeNavbar) {
      document.body.classList.remove('stop-scroll')
    } else {
      document.body.classList.add('stop-scroll')
    }
  }

  const goToNextSection = (section) => {
    const targetSection = document.getElementById(section)
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' })
    }
  }

  function handleResize() {
    const screenWidth = window.innerWidth
    if (screenWidth >= 768) {
      console.log('im working!')
      // Mobile behavior
      if (activeNavbar) {
        setactiveNavbar(false)
      }
      document.body.classList.remove('stop-scroll')
    }
  }

  // Event listener for window resize
  window.addEventListener('resize', handleResize)
  handleResize() // Call the function initially


  return (
    <div id="App">

      <div id="generalContainer">
        <SocialLinksString />

        <div id="header-body-container">
          <div>
            {isNotFound ? null : <Header handleSectionVisibility={handleSectionVisibility} currentLang={currentLang} onLangChange={handleLangChange} isTranslateLoaded={isTranslateLoaded} translations={translations} handleCookieNoticeChange={handleCookieNoticeChange} hambMenuInteraction={hambMenuInteraction} activeNavbar={activeNavbar} goToNextSection={goToNextSection} />}
          </div>

          <div id="main-content" className={isNotFound ? 'active-background height100vh ' : isAboutWebsite ? 'black-background' : isAboutMe ? 'about-meBg' : activeNavbar ? 'adjust-content-for-navbar' : 'inactive-background'}>
            <Routes>

              <Route path="/about-this-website" element={<div className={`${activeNavbar ? 'adjust-content-for-navbar' : ''} about-website-container-container`}><AboutWebsiteLayout translations={translations} isTranslateLoaded={isTranslateLoaded} currentLang={currentLang} /><AboutWebsiteLayoutNavbar translations={translations} isTranslateLoaded={isTranslateLoaded} currentLang={currentLang} /></div>} />

              <Route path="/about-me" element={<AboutMe translations={translations} isTranslateLoaded={isTranslateLoaded} currentLang={currentLang} activeNavbar={activeNavbar} />} />

              <Route exact path="/" element={<Body handleSectionVisibility={handleSectionVisibility} visibleSections={visibleSections} translations={translations} isTranslateLoaded={isTranslateLoaded} currentLang={currentLang} />} />
              {/* Other routes */}
              {/* Define a separate route for NotFound */}
              <Route path="/not-found" element={<NotFound />} />
              {/* Add the CatchAllRoute as the last route */}
              <Route path="*" element={<CatchAllRoute />} />
            </Routes>
          </div>

          <div>
            <CookieNotice handleCookieNoticeChange={handleCookieNoticeChange} showCookieBanner={showCookieBanner} currentLang={currentLang} />
          </div>

          <div className={activeNavbar ? 'adjust-content-for-navbar ' : ''}>
            {isNotFound ? null : <Footer id="footer" isTranslateLoaded={isTranslateLoaded} translations={translations} currentLang={currentLang} activeNavbar={activeNavbar} />}
          </div>
        </div>

      </div>



    </div>

  )
}

export default App
