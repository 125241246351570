import React from 'react'
import profileImage from '../../Images/profilePhoto.png'
import { HashLink } from 'react-router-hash-link'


/* POSSIBLE VIDEOS*/
import wavesBeach from '../../Images/videos/wavesBeach.mp4'

const wavesBeachDeployment = 'https://www.martinzazpe.com/videos/wavesBeach.mp4'

export const Banner = ({ isTranslateLoaded, translations }) => {

    if (!isTranslateLoaded) {
        return

    }
    else {
        return (
            <div id="banner-container">
                <div id="text-container">
                    <video autoPlay loop muted playsInline id="myVideo" style={{ transform: 'scaleX(-1)' }}  >
                        <source src={process.env.NODE_ENV == "development" ? wavesBeach : wavesBeachDeployment} type="video/mp4" />
                    </video>
                    <div id="title">
                        <h1 className='see-through-title'>
                            {translations[1].navbar.bannerHello}
                            <br></br>
                            {translations[1].navbar.bannerName}
                        </h1>
                    </div>
                    <div id="subtitle">
                        <h3>{translations[1].navbar.bannerPhraseP1}</h3>
                        {/* <br /> */}
                        <h3>{translations[1].navbar.bannerPhraseP2}</h3>
                    </div>
                </div>

                {/* <div id="image-container-container">
                <div className="image-container">
                    <img className="img" src={profileImage} alt="" />
                </div>

            </div> */}


                <div id="links-container">
                    <div id="links-container-container">

                        <div id="portfolio-link-container"><a id="portfolio-link" href="#projects">{translations[1].navbar.portfolio}</a></div>
                        <div id="lets-talk-link-container"><a id="lets-talk-link" href="#footer-container">{translations[1].navbar.letsTalk}</a></div>
                    </div>
                </div>

            </div >

        )
    }
}
