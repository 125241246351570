import React, { useEffect } from 'react'
import '../../styles/about-me.css'
import aboutMeImg from '../../Images/about-me-img.jpg'
import profileImgComplete from '../../Images/profile-img-complete.png'

export const AboutMe = ({ isTranslateLoaded = false, translations, currentLang, activeNavbar }) => {

    useEffect(() => {
        const hash = window.location.hash
        if (hash) {
            const element = document.querySelector(hash)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        } else {
            window.scrollTo(0, 0)
        }
    }, [])



    if (!isTranslateLoaded) {
        return
    } else {
        return (
            <div className={`about-me-container ${activeNavbar ? 'adjust-content-for-navbar' : ''}`}>
                <div className="about-me-header">
                    {/* <img
                    className="about-me-image"
                    src="https://via.placeholder.com/150"
                    alt="Profile"
                /> */}
                    <div className="about-me-heading">
                        {/* <h2>Martin</h2>
                    <p className="about-me-subheading">Web Developer</p> */}
                    </div>
                </div>
                <div className="about-me-content">
                    <div className='first-paragraph-container'>
                        <p className="first-paragraph">

                            <h3 className='about-title'>{translations[1].aboutMe.title}</h3>
                            {translations[1].aboutMe.p1}
                            <br></br>
                            {translations[1].aboutMe.p2}
                            <br></br>
                            <span className='programming-languages'>{translations[1].aboutMe.p3}</span>
                        </p>
                    </div>
                    <br></br>
                    <div className='second-paragraph-container'>
                        <p className="second-paragraph">
                            <img
                                src={profileImgComplete}
                                alt="Profile Image"
                                className="about-me-img"
                            />
                            {translations[1].aboutMe.p4}
                        </p>
                    </div>
                    <br></br>
                    <p>
                        {translations[1].aboutMe.p5}{' '}
                        <a href="https://shorturl.at/qu569" className="about-me-link" target='_blank'>
                            {translations[1].aboutMe.p6}
                        </a>{' '}
                        {translations[1].aboutMe.p7}
                    </p>
                    <br></br>
                    <p>
                        <strong>{translations[1].aboutMe.p8}</strong>{translations[1].aboutMe.p9}
                    </p>
                    <p>
                        {translations[1].aboutMe.p10}
                    </p>
                </div>
            </div>

        )
    }
}