import React from 'react'
import '../../styles/aboutWebsite.css'
import { Link } from 'react-router-dom'

function AboutWebsite({ isTranslateLoaded = false, translations, currentLang }) {

    if (!isTranslateLoaded) {
        return
    } else {
        return (
            <div id="about-website" >
                <h1 id="about-website-title"> {translations[1]?.aboutWebsite.title}</h1>

                <p> {translations[1]?.aboutWebsite.p1}</p>
                <p> {translations[1]?.aboutWebsite.p2}</p>
                <p> {translations[1]?.aboutWebsite.p3} </p>

            </div>
        )
    }
}


export default AboutWebsite
