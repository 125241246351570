import React from 'react'
import '../../styles/ErrorView.css'
import { Link } from 'react-router-dom'

export const NotFound = () => {
    document.title = 'Page not found!'

    return (
        <div>


            <div id="error-container">

                <img id="error-img" src="https://images.squarespace-cdn.com/content/v1/61583236df8ffd37e403bb6c/303b8f57-3e45-4216-8e1c-6858ec8cc93c/cat_img_1628319058149.png" />
                <div id="error-text-container">
                    <h1 id="error-title">Error!</h1>
                </div>
            </div>


            <div id="error-information">
                <div>
                    <em>Upps, something went wrong or the website you tried to access doesn't exist.</em>
                </div>
                <br />
                <div>
                    <em>But don't worry, here are a few links that may interest you:</em>
                </div>

                <nav id="error-nav">
                    <Link class="error-link-home" to="/">Get back to: <span>Martinzazpe.com</span></Link>
                    <a class="error-link-home" to="/#projects">Have you checked out my <em>collection of small projects </em>?</a>
                </nav>
            </div>

            <div id="copyright-container-error-page">
                <p>©2023 Designed & developed by <span>Martin Zazpe</span></p>
            </div>

        </div>
    )
}
